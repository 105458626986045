// styles/main.sass

@import "../../variables.scss";

.header {
  position: relative; // Set the header as a relative container
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 28px 15px;
  background: $primary-color;
  color: $secondary-color;

  .logo {
    width: 64px;
    height: 64px;
    left: 10px; // Adjust the left position as needed
    position: absolute;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .mobile-header {
    display: none; // Initially hide the menu icon
  }

  .nav {
    margin-left: 160px; // Add a left margin for the navigation menu
    ul {
      list-style-type: none;
      padding: 0;
      display: flex;

      li {
        margin: 0;
        padding: 0 10px;
        font-weight: 700;
        cursor: pointer;

        a {
          text-decoration: none; // Remove underline
          color: inherit; // Inherit the color from the parent
          font-weight: bold; // Set the font-weight to bold or your preferred value
        }

        &.selected {
          text-underline-offset: 0.2em;
          text-decoration-color: $secondary-color;
          text-decoration-line: underline;
        }
      }
    }
  }

  .login {
    cursor: pointer;
    font-size: 1.1em;
    font-weight: 600;
    border: 1px solid $secondary-color;
    padding: 6px;

    a {
      text-decoration: none; // Remove underline
      color: inherit; // Inherit the color from the parent
    }
  }

  
}

// Media query for screens between 320px and 935px
@media (max-width: 935px) {
  .header {
   padding: 15px;

    .logo {
      display: none; // Hide the logo
    }

    .mobile-header {
      display: block; // Show the menu icon
      font-size: 20px;
      display: flex;
      width: 100%;
      justify-content: space-between;

      .menu-icon {
        font-size: 2em;
        cursor: pointer;
      }
      .welcome {
        display: flex;
        align-items: center;
        font-size: 17px;

        img {
          width: 60px;
        }
      }
    }

    .nav {
      display: none; // Hide the navigation menu
    }
  }
}
