@import "../../variables.scss";

.footer {
  margin-top: 200px;
  background: $primary-color;
  padding: 60px 40px;

  .footer-header {
    display: flex;
    justify-content: space-between;
    border-bottom: 0.1px solid $secondary-color-second;
    padding-bottom: 35px;

    .logo {
      img {
        width: 38px;
        height: 38px;
        left: 10px;
        object-fit: cover;
      }

      .welcome-text {
        color: $secondary-color-second;
        text-transform: uppercase;
        margin-top: 5px;
      }
    }

    .social-icons {
      display: flex;

      a {
        margin-left: 15px;
        height: 40px;
        width: 40px;
        display: block;
        border-radius: 50%;
        position: relative;
        background: transparent;
        border: 1px solid $secondary-color-second;

        span {
          position: absolute;
          font-size: 20px;
          top: 50%;
          left: 50%;
          -webkit-transform: translate(-50%, -50%);
          -ms-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);

          svg {
            color: $secondary-color-second;
          }
        }
      }
    }
  }

  .footer-info {
    color: rgba(255, 255, 255, 0.5);
    .about-us {
      list-style: none;

      li {
        padding: 9px 0;

        svg {
          margin-right: 6px;
        }

        a {
          color: rgba(255, 255, 255, 0.5);
        }
      }
    }
  }
}
