@import "../../../variables.scss";

.dropdown-container {
  position: relative;
  display: inline-block;
  background-color: #e8e4e4;
  border-radius: 3px;

  .dropdown {
    width: 60px;
    padding: 5px;
    font-size: 14px;
    color: #000000a6;
    font-weight: 600;
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;

    svg {
      margin-left: 6px;
      margin-top: 3px;
    }
  }

  .dropdown-content {
    position: absolute;
    top: 29px;
    width: 60px;
    color: #000000a6;
    border-radius: 3px;
    background-color: #e8e4e4;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    z-index: 9;
  }

  .dropdown-content button {
    width: 100%;
    text-align: left;
    padding: 8px 5px;
    border: none;
    color: #000000a6;
    font-weight: 600;
    font-size: 14px;
    cursor: pointer;
    transition: background-color 0.3s;
    display: flex;
    align-items: center;
    justify-content: space-between;

    svg {
      font-size: 12px;
      margin-left: 5px;
    }

    &:hover {
      background-color: #ddd;
    }
  }
}

@media (max-width: 600px) {
  .dropdown-container {
    .dropdown {
      width: 48px;
      font-size: 11px;

      svg {
        margin-left: 1px;
        margin-top: 1px;
      }
    }
  }
}
