// MobileMenu.scss
@import "../../variables.scss";

.mobile-menu {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #fff; // Set the background color directly
  z-index: 1000;
  transition: transform 0.3s ease-in-out;
  overflow-y: auto; // Allow scrolling if the content exceeds the screen height

  .menu-header {
    background-color: $primary-color; // Set the background color for the header
    padding: 15px 15px 15px 0; // Adjust padding as needed
    display: flex;
    justify-content: space-between;
    align-items: center;

    img {
      width: 60px;
    }

    .close-icon {
      font-size: 1.5em;
      cursor: pointer;
      color: $secondary-color; // Set the color of the close icon`
      transition: color 0.3s ease;
    }
  }

  .mobile-nav {
    list-style-type: none;
    padding: 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start; /* Align items to the left */

    li {
      margin: 0;
      padding: 15px 10px;
      border-bottom: 1px solid #ccc;
      width: 100%;

      &:last-child {
        border-bottom: none;
      }

      &.selected {
        text-decoration: underline;

        a {
          color: $primary-color;
        }
      }

      a {
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: $secondary-color;
        text-decoration: none;
        font-size: 19px;
        font-weight: 600;

        svg {
          font-size: 1.2em;
          color: $primary-color; /* Change color as needed */
          margin-left: auto; /* Push the icon to the right */
        }
      }
    }
  }
}
